<template>
  <div
    class="help-needed"
    v-if="helpContent && helpContent.active && helpContent.data"
  >
    <div class="heading">
      {{ helpContent.data.title }}
    </div>
    <div class="contact-info">
      <div>{{ helpContent.data.textFirstRow }}</div>
      <div>{{ helpContent.data.textSecondRow }}</div>
      <CustomCta
        v-if="helpContent.data.phone"
        :link="helpContent.data.phone['link-url']"
        aria-label="phone"
        class="phone-cta"
      >
        {{ helpContent.data.phone['link-label'] }}
      </CustomCta>
      <CustomCta
        v-if="helpContent.data.email"
        :link="helpContent.data.email['link-url']"
        aria-label="email : info@sognidicristallo.it"
        class="email-cta"
      >
        {{ helpContent.data.email['link-label'] }}
      </CustomCta>
    </div>
  </div>
</template>

<script>
import {
  ref,
  useFetch,
  useContext,
  defineComponent,
} from '@nuxtjs/composition-api';
import CustomCta from './CustomCta.vue';
import { contentGetters } from '~/composables';
import { useContent } from '@gemini-vsf/composables';

export default defineComponent({
  name: 'NeedHelp',
  components: {
    CustomCta,
  },
  setup() {
    const {
      app: {
        i18n: {
          localeProperties: { localeValue },
        },
      },
    } = useContext();
    const { blocks, loadBlocks } = useContent(`need-help-${localeValue}`);

    const helpSelector = `help-${localeValue}`;
    const helpContent = ref();

    useFetch(async () => {
      await loadBlocks({
        identifiers: [helpSelector],
      });
      const blockData = await contentGetters.getCmsBlockContent(blocks.value);
      helpContent.value = blockData[helpSelector];
    });

    return {
      helpContent,
    };
  },
});
</script>

<style lang="scss" scoped>
.help-needed {
  padding: 2.5rem 1.5625rem;
  @include for-desktop {
    padding: 5rem 1.25rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .heading {
    padding-bottom: 1.875rem;
    @include mobile-h5;
    @include for-desktop {
      margin-right: 7.5rem;
      max-width: 13.4375rem;
      @include desktop-h5;
    }
  }
  .contact-info {
    @include paragraph-m;
    .phone-cta {
      margin-top: 1.25rem;
      @include for-desktop {
        margin-top: 1.5625rem;
      }
    }
    .email-cta {
      margin-top: 1.25rem;
    }
  }
}
</style>
