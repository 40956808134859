<template>
  <div
    v-if="data && (!isDesigner || data.active)"
    class="image-with-text"
    :class="{ 'designer-section': isDesigner }"
  >
    <div class="image-with-text__content">
      <div class="image-with-text__content__intro">
        {{ data.intro || data.subtitle }}
      </div>
      <div class="image-with-text__content__title">
        {{ data.title }}
      </div>
      <div class="image-with-text__content__text" v-if="data.text">
        {{ data.text }}
      </div>
      <div class="image-with-text__content__text" v-if="data.description">
        <HTMLContent :content="data.description" v-if="data.description" />
      </div>
      <CustomCta
        v-if="data.link && data.link['link-url'] && data.link['link-label']"
        :type="isDesigner ? 'label' : 'button'"
        class="image-with-text__content__cta"
        :link="localePath(data.link['link-url'])"
      >
        {{ data.link['link-label'] }}
      </CustomCta>
    </div>
    <div class="image-with-text__image">
      <nuxt-img
        v-if="
          data.image && (isDesktop ? data.image.desktop : data.image.mobile)
        "
        :src="isDesktop ? data.image.desktop : data.image.mobile"
        loading="lazy"
        placeholder
        class="image-with-text__image__img"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { CustomCta } from '~/components/General';
import { useWindow } from '~/composables';
import HTMLContent from '../HTMLContent.vue';

export default defineComponent({
  name: 'ImageWithText',
  components: {
    CustomCta,
    HTMLContent,
  },
  props: {
    data: {
      type: Object,
      default: () => null,
    },
    isDesigner: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { isDesktop } = useWindow();
    return { isDesktop };
  },
});
</script>

<style lang="scss" scoped>
.image-with-text {
  &__content {
    margin: 2.5rem 1.25rem;
    &__intro {
      @include label-s;
      margin: 0 0 0.625rem;
    }
    &__title {
      @include mobile-h4-lowercase;
    }
    &__subtitle {
      @include mobile-h4-lowercase;
      margin: 0 0 1.875rem;
    }
    &__text {
      @include paragraph-m;
      margin: 0 0 1.375rem 18%;
    }
    &__cta {
      margin: 0 0 0 18%;
    }
  }
  &__image {
    margin: 2.5rem 0;
    &__img {
      width: 100%;
    }
  }
  &.designer-section {
    .image-with-text {
      &__image {
        margin-left: 1.25rem;
        margin-right: 1.25rem;
      }
    }
  }
  @include from-desktop-min {
    display: flex;
    width: 100%;
    &__content {
      width: 55%;
      margin: 5rem 1.25rem 5rem 8%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &__title {
        @include desktop-h4-lowercase;
      }
      &__subtitle {
        @include desktop-h4-lowercase;
      }
      &__text {
        max-width: 21.25rem;
      }
    }
    &__image {
      width: 45%;
      margin: 5rem 1.25rem;
    }
  }
}
</style>
