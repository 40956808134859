<template>
  <div
    v-if="optionsArray"
    class="custom-select"
    :class="[fullWidth && 'custom-select-full-width']"
  >
    <ValidationProvider
      :rules="isRequired ? 'required' : ''"
      v-slot="{ errors }"
      class="custom-select__container"
      :class="isDisabled && 'custom-select__container__disabled'"
    >
      <SfSelect
        class="custom-select__select"
        :label="selectLabel"
        :required="isRequired"
        :valid="isValid"
        :disabled="isDisabled"
        placeholder=""
        @input="handleChange"
        :value="selectValue || (defaultValue && defaultValue.value)"
      >
        <SfSelectOption
          v-for="(option, index) in optionsArray"
          class="custom-select__select__option"
          :value="option.value"
          :key="option.value + index"
        >
          {{ option.label }}
        </SfSelectOption>
      </SfSelect>
      <component
        v-if="selectIcon"
        class="custom-select__icon"
        :is="selectIcon"
      />
      <span v-if="errors[0]" class="custom-select__error">
        {{ $t(errors[0]) }}
      </span>
    </ValidationProvider>
  </div>
</template>

<script>
import { defineComponent, ref, computed, watch } from '@nuxtjs/composition-api';
import { IconMoreBlack, IconMoreBlack1616 } from '~/components/General/Icons';
import { ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { SfSelect } from '@storefront-ui/vue';

extend('required', {
  ...required,
  message: 'Required field',
});
export default defineComponent({
  name: 'CustomSelect',
  components: {
    IconMoreBlack,
    IconMoreBlack1616,
    ValidationProvider,
    SfSelect,
  },
  model: {
    prop: 'selectedValue',
  },
  props: {
    selectName: {
      type: String,
      required: true,
    },
    selectOptions: {
      type: Array,
      required: true,
    },
    selectIcon: {
      type: String,
      default: 'IconMoreBlack',
    },
    isRequired: {
      type: Boolean,
      default: true,
    },
    selectLabel: {
      type: String,
      default: 'Select option',
    },
    selectedValue: {
      type: [String, null],
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const selectValue = ref(props.selectedValue);
    const defaultValue = ref();
    const optionsArray = computed(() =>
      props.selectOptions && props.selectOptions.length > 0
        ? props.selectOptions
            .map((e) => {
              if (!('label' in e)) {
                e.label = e.value;
              }
              if ('default' in e) {
                defaultValue.value = e;
                return null;
              }
              return e;
            })
            .filter(Boolean)
        : []
    );

    const handleChange = (value) => {
      selectValue.value = value;
      emit('selectChange', value);
      emit('input', value);
    };

    watch(optionsArray, (newOptionsArray) => {
      if (newOptionsArray.length === 1) {
        selectValue.value = newOptionsArray[0].value.toString();
        handleChange(selectValue.value);
      }
    });

    return {
      optionsArray,
      selectValue,
      handleChange,
      defaultValue,
    };
  },
});
</script>

<style lang="scss" scoped>
.custom-select {
  width: var(--select-width);
  &-full-width {
    width: 100%;
  }
  &__container {
    position: relative;
    display: block;
    &__disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  &__error {
    @include paragraph-s;
    padding: var(--select-error-padding);
    color: var(--c-red-error);
  }
  &__icon {
    position: absolute;
    right: 1.25rem;
    top: 0.8125rem;
  }
}
</style>

<style lang="scss">
.custom-select__select {
  .sf-select {
    &__dropdown {
      border-radius: var(--input-border-radius);
      height: var(--select-height);
      padding: var(--select-dropdown-padding);
      appearance: none;
      @include paragraph-m;
      &:focus-visible {
        outline: none;
      }
      &:focus ~ .sf-select__label {
        @include label-s-lowercase;
        color: var(--c-dark-gray);
      }
    }
    &__label {
      @include paragraph-m;
      padding: var(--select-label-padding);
    }
    &__error-message {
      display: none;
    }
  }
  &.is-selected {
    .sf-select__dropdown {
      color: var(--c-text);
    }
    .sf-select__label {
      @include label-s-lowercase;
      color: var(--c-dark-gray);
    }
  }
}
.custom-select-full-width {
  .custom-select__select.sf-select {
    width: 100%;
  }
}
</style>
