<template>
  <img :key="nSrc" v-bind="nAttrs" ref="img" :src="nSrc" v-on="$listeners" />
</template>

<script>
import { imageMixin, parseSize } from './image.mixin';

const defineComponent = (options) => options;
export default defineComponent({
  name: 'NuxtImgCustom',
  mixins: [imageMixin],
  props: {
    placeholder: { type: [Boolean, String, Number, Array], default: void 0 },
    hideOnError: { type: Boolean, default: false },
  },
  head() {
    if (this.preload === true) {
      return {
        link: [
          {
            rel: 'preload',
            as: 'image',
            href: this.nSrc,
          },
        ],
      };
    }
    return {};
  },
  computed: {
    nAttrs() {
      const attrs = this.nImgAttrs;
      if (this.sizes) {
        const { sizes, srcset } = this.nSizes;
        attrs.sizes = sizes;
        attrs.srcset = srcset;
      }
      return attrs;
    },
    mounted() {
      if (this.nPlaceholder) {
        const img = new Image();
        img.src = this.nMainSrc;
        img.addEventListener('load', () => {
          this.$refs.img.src = this.nMainSrc;
          this.placeholderLoaded = true;
        });
      }
      if (this.hideOnError) {
        const img = new Image();
        img.src = this.nMainSrc;
        img.addEventListener('error', () => {
          this.$refs.img.style.display = 'none';
        });
      }
    },
    nMainSrc() {
      return this.sizes
        ? this.nSizes.src
        : this.$img(this.src, this.nModifiers, this.nOptions);
    },
    nSizes() {
      return this.$img.getSizes(this.src, {
        ...this.nOptions,
        sizes: this.sizes,
        modifiers: {
          ...this.nModifiers,
          width: parseSize(this.width),
          height: parseSize(this.height),
        },
      });
    },
    nSrc() {
      return this.nPlaceholder || this.nMainSrc;
    },
    nPlaceholder() {
      let placeholder = this.placeholder;
      if (placeholder === '') {
        placeholder = true;
      }
      if (!placeholder || this.placeholderLoaded) {
        return false;
      }
      if (typeof placeholder === 'string') {
        return placeholder;
      }
      const size = Array.isArray(placeholder)
        ? placeholder
        : typeof placeholder === 'number'
        ? [placeholder, placeholder]
        : [10, 10];
      return this.$img(
        this.src,
        {
          ...this.nModifiers,
          width: size[0],
          height: size[1],
          quality: size[2] || 50,
        },
        this.nOptions
      );
    },
  },
  mounted() {
    if (this.nPlaceholder) {
      const img = new Image();
      img.src = this.nMainSrc;
      img.addEventListener('load', () => {
        if (this?.$refs?.img?.src) {
          this.$refs.img.src = this.nMainSrc;
        }
        this.placeholderLoaded = true;
      });
    }
    if (this.hideOnError) {
      const img = new Image();
      img.src = this.nMainSrc;
      img.addEventListener('error', () => {
        if (this?.$refs?.img) {
          this.$refs.img.style.display = 'none';
        }
      });
    }
    if (process.server && process.static && this.sizes) {
      this.nSizes;
    }
  },
});
</script>
