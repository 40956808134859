<template>
  <div class="custom-qty-selector">
    <SfQuantitySelector
      class="custom-qty-selector__selector"
      :disabled="isDisabled"
      :min="minQuantity"
      :max="maxQuantity"
      v-model="selectedQuantity"
    />
  </div>
</template>

<script>
import { defineComponent, watch, ref } from '@nuxtjs/composition-api';
import { SfQuantitySelector } from '@storefront-ui/vue';

export default defineComponent({
  name: 'CustomQuantitySelector',
  components: {
    SfQuantitySelector,
  },
  props: {
    quantity: {
      type: Number,
    },
    minQuantity: {
      type: Number,
      default: null,
    },
    maxQuantity: {
      type: Number,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const selectedQuantity = ref(props.quantity);
    watch(selectedQuantity, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        emit('onChange', newValue);
      }
    });

    return {
      selectedQuantity,
    };
  },
});
</script>

<style lang="scss">
.custom-qty-selector {
  width: fit-content;
  &__selector {
    border-radius: var(--border-radius);
    button {
      border: none;
      padding: 0;
      width: auto;
      @include paragraph-m;
      &:first-child {
        padding-left: 1.0313rem;
      }
      &:last-child {
        padding-right: 1.0313rem;
      }
      &.is-disabled--button {
        background: none;
      }
    }
    input {
      @include paragraph-m;
      pointer-events: none;
      &:focus-visible {
        outline: none;
      }
    }
  }
}
</style>
