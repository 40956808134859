<template>
  <div
    class="custom-checkmark"
    :class="[{ 'is-selected': selected }, { 'circle-radius': circleRadius }]"
  >
    <span class="custom-checkmark__inner" :class="{ disabled: disabled }" />
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'CustomCheckmark',
  components: {},
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    circleRadius: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {};
  },
});
</script>

<style lang="scss">
.custom-checkmark {
  min-width: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--checkbox-size);
  height: var(--checkbox-size);
  border: var(--general-border);
  border-radius: var(--border-radius-small);
  &.is-selected {
    .custom-checkmark__inner {
      width: var(--checkbox-checkmark-size);
      height: var(--checkbox-checkmark-size);
      background-color: var(--c-black);
      &.disabled {
        background-color: var(--c-dark-gray);
      }
    }
  }
  &.circle-radius {
    border-radius: 1000rem;
    &.is-selected {
      .custom-checkmark__inner {
        border-radius: 1000rem;
      }
    }
  }
}
</style>
