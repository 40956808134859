<template>
  <div
    :class="[
      `custom-input custom-input-${inputType}`,
      fullWidth && 'custom-input-full-width',
      disabled && 'custom-input-disabled',
    ]"
  >
    <ValidationProvider
      :rules="validateRule"
      v-slot="{ errors }"
      class="custom-input__container"
      :mode="interactionMode"
      :ref="inputId"
      :vid="inputId"
    >
      <SfInput
        :id="inputId"
        :name="inputName"
        :label="$t(inputLabel)"
        :type="inputType"
        v-model="value"
        :disabled="disabled"
        class="custom-input__input"
        :class="errors[0] && 'has-errors'"
        @input="handleChange()"
      />
      <component v-if="icon" class="custom-input__icon" :is="icon" />
      <div v-if="errors[0]" class="custom-input__error">
        {{ $t(errors[0]) }}
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from '@nuxtjs/composition-api';
import { ValidationProvider, extend } from 'vee-validate';
import {
  email,
  required,
  alpha_num,
  confirmed,
  numeric,
} from 'vee-validate/dist/rules';
import { PointRightBlack } from '~/components/General/Icons';
import libphonenumber from 'google-libphonenumber';
import { useI18n } from '~/helpers/hooks/usei18n';
import { SfInput } from '@storefront-ui/vue';

extend('numeric', {
  ...numeric,
  message: 'May only contain numeric characters',
});
extend('email', {
  ...email,
  message: 'The email format is not correct',
});
extend('alpha_num', {
  ...alpha_num,
  message: 'May only contain alpha-numeric characters',
});
extend('required', {
  ...required,
  message: 'Required field',
});
extend('confirmed', {
  ...confirmed,
  message: 'Field does not match',
});
extend('phone', {
  validate(value, arguments_) {
    if (
      !arguments_ ||
      !Array.isArray(arguments_) ||
      arguments_.length === 0 ||
      arguments_[0] === ''
    )
      return false;
    const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
    try {
      return phoneUtil.isValidNumberForRegion(
        phoneUtil.parse(value, arguments_[0]),
        arguments_[0]
      );
    } catch {
      return false;
    }
  },
});

export default defineComponent({
  name: 'CustomInput',
  components: {
    ValidationProvider,
    PointRightBlack,
    SfInput,
  },
  model: {
    prop: 'inputValue',
  },
  props: {
    inputType: {
      type: String,
      default: 'text',
    },
    inputPlaceholder: {
      type: String,
      default: '',
    },
    inputName: {
      type: String,
      required: true,
    },
    inputValue: {
      type: [String, Object, null],
      default: null,
    },
    inputLabel: {
      type: String,
      required: true,
    },
    inputId: {
      type: String,
      required: true,
    },
    validateRule: {
      type: [Boolean, String],
      default: '',
    },
    interactionMode: {
      type: String,
      default: 'aggressive',
    },
    icon: {
      type: [Boolean, String],
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const value = ref(props.inputValue);

    const isEmail = computed(
      () =>
        (props.validateRule && props.validateRule.includes('email')) ||
        props.inputType === 'email'
    );

    const handleChange = () => {
      emit(
        'inputChange',
        value.value && isEmail.value ? value.value.toLowerCase() : value.value
      );
      emit(
        'input',
        value.value && isEmail.value ? value.value.toLowerCase() : value.value
      );
    };

    const trans = useI18n();

    extend('min', {
      validate(v, arguments_) {
        return v.length >= arguments_.length;
      },
      params: ['length'],
      message: (_, arguments_) => {
        return trans.t(`Must be longer than than {0} characters`, [
          arguments_.length,
        ]);
      },
    });
    extend('max', {
      validate(v, arguments_) {
        return v.length < arguments_.length;
      },
      params: ['length'],
      message: (_, arguments_) => {
        return trans.t(`Must be shorter than {0} characters`, [
          arguments_.length,
        ]);
      },
    });

    extend('customPassword', {
      message: () =>
        trans.t(
          'Your password must include at least one lowercase character, one uppercase character and one digit.'
        ),
      validate: (password) => {
        // code contains at least one digit, at least one lowercase char and at least one uppercase char
        const passwordIncludesDigit = /\d/.test(password);
        const passwordIncludesUppercaseCharacter = /[A-Z]/.test(password);
        const passwordIncludesLowercaseCharacter = /[a-z]/.test(password);
        return (
          passwordIncludesDigit &&
          passwordIncludesUppercaseCharacter &&
          passwordIncludesLowercaseCharacter
        );
      },
    });

    return {
      value,
      PointRightBlack,
      handleChange,
    };
  },
});
</script>

<style lang="scss" scoped>
.custom-input {
  width: var(--input-width);
  &-full-width {
    width: 100%;
  }
  &__container {
    position: relative;
  }
  &__error {
    @include paragraph-s;
    padding: var(--input-error-padding);
    color: var(--c-red-error);
  }
  &__icon {
    position: absolute;
    right: 0.9375rem;
    top: 1rem;
  }
}
</style>

<style lang="scss">
.custom-input__input {
  .sf-input {
    &__wrapper {
      input {
        border-radius: var(--input-border-radius);
        @include paragraph-m;
        @include to-tablet-max {
          font-size: 1rem;
        }
        &:focus-visible {
          outline: none;
        }
        &:focus ~ .sf-input__label {
          @include label-s-lowercase;
          color: var(--c-dark-gray);
        }
      }
    }
    &__label {
      @include paragraph-m;
    }
    &__error-message {
      display: none;
    }
  }
  &.has-text {
    .sf-input__label {
      @include label-s-lowercase;
      color: var(--c-dark-gray);
    }
  }
}
.custom-input-full-width {
  .custom-input__input.sf-input {
    width: 100%;
  }
}
</style>
